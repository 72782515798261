<template>
  <v-row cols="12" sm="3" md="3" lg="3" xl="3">
    <v-col>
      <v-btn class="mr-0 pr-0" v-for="language in languages" :key="language.value" plain
        @click="changeLocale(language)">
        <img class="flag" :src="language.flag" alt="flag">{{ language.text }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import getFirebaseConfig from '../utils/firebaseConfig';

export default {
  name: 'LocaleSwitcher',
  data() {
    return {
      languages: [
        { text: 'EN', value: 'en', flag: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg' },
        { text: 'ES', value: 'es', flag: 'https://cdn.kcak11.com/CountryFlags/countries/es.svg' },
      ],
    };
  },
  methods: {
    ...mapActions(['setSelectedLanguage']),
    async addEvent(eventName, passName) {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        const analytics = getAnalytics(app);
        logEvent(analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    changeLocale(language) {
      this.$i18n.locale = language.value;
      this.setSelectedLanguage(language.value);
      this.addEvent('change_language', language.text);
    },
  },
};
</script>
<style>
.flag {
  width: 20px;
  height: 15px;
}

.float-right {
  padding-top: 15px;
}
</style>
